<template>
    <div class="programme pt-3 mb-3 mb-md-5--5">
        <h2 class="title-section mb-5">Partenaires</h2>

        <div class="d-flex-center flex-wrap container gap-5--5">
            <img
                src="../assets/img/partners/saint-etienne-metropole.jpg"
                alt=""
                class="mw-100"
                style="max-height: 7rem"
            />
            <img src="../assets/img/partners/chu-saint-etienne.png" alt="" class="mw-100" style="max-height: 7rem" />
            <img src="../assets/img/partners/institut-presage.jpg" alt="" class="mw-100" style="max-height: 7rem" />
            <img src="../assets/img/partners/rotonde.png" alt="" class="mw-100" style="max-height: 7rem" />
            <img src="../assets/img/partners/minalogic.png" alt="" class="mw-100" style="max-height: 7rem" />
            <img src="../assets/img/partners/ciridd.png" alt="" class="mw-100" style="max-height: 7rem" />
            <img src="../assets/img/partners/neurodis.png" alt="" class="mw-100" style="max-height: 7rem" />
            <img src="../assets/img/partners/clara-canceropole.png" alt="" class="mw-100" style="max-height: 7rem" />
            <img
                src="../assets/img/partners/ligue-contre-le-cancert.jpg"
                alt=""
                class="mw-100"
                style="max-height: 7rem"
            />
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
};
</script>
