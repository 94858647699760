<template>
    <div class="container-lg py-3" id="accueil">
        <div v-if="isFromTY" class="alert alert-success text-white d-flex align-items-center rounded-1" role="alert">
            <i class="fa-solid fa-check me-3"></i>
            <div>Merci pour votre inscription, un email de confirmation vous a été envoyé.</div>
        </div>
        <AccueilView ref="home" id="home" />
        <ProgrammeView ref="programme" id="programme" />
        <AccesView ref="acces" id="acces" />
        <PartenairesView ref="partners" id="partners" />
    </div>
</template>

<script>
import AccueilView from "./_AccueilView.vue";
import ProgrammeView from "./_ProgrammeView.vue";
import AccesView from "./_AccesView.vue";
import PartenairesView from "./_PartenairesView.vue";

export default {
    components: {
        AccueilView,
        ProgrammeView,
        AccesView,
        PartenairesView
    },
    data() {
        let _isFromTY = localStorage.fromty && JSON.parse(localStorage.fromty);
        localStorage.fromty = false;

        return { isFromTY: _isFromTY };
    },
};
</script>
